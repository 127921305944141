import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';

import { IAppConfig } from './core/models/app-config.model';


@Injectable({
  providedIn: 'root'
})
export class AppConfig {
  settings: IAppConfig;


  constructor(private http: HttpClient, private titleService: Title) {}


  load() {
    const jsonFile = `assets/config/config.json`;
    return new Promise<void>((resolve, reject) => {
      this.http.get(jsonFile).toPromise().then((response: IAppConfig) => {
        this.settings = <IAppConfig>response;
        this.init();
        resolve();
      }).catch((response: any) => {
        reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
      });
    });
  }


  private init() {
    this.setAppTitle();
  }


  private setAppTitle() {
    this.titleService.setTitle(this.settings.app.name);
  }
}
