import { Injectable } from '@angular/core';

import { AppConfig } from '../../app.config';
import { AnalyticsEventModel } from '../models/analytics-event.model';


@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private active = false;
  private gaCode = 'ga';

  constructor(private appConfig: AppConfig) {
    if (this.appConfig.settings.analytics.gaActive) {
      this.initializeAnalytics();
    }
  }


  initializeAnalytics() {
    const self = this;
    const gaURL = `//www.google-analytics.com/analytics${this.appConfig.settings.analytics.gaDebug ? '_debug' : ''}.js` ;
    (function (i, s, o, g, r, a?, m?) {
      i['GoogleAnalyticsObject'] = r;
      i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments);
      }, i[r].l = 1 * <any>new Date();
      a = s.createElement(o),
        m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
      if (self.appConfig.settings.analytics.gaDebug) {
        window['ga_debug'] = {trace: true};
      }
      window[r]('create', self.appConfig.settings.analytics.gaProperty, {
        'cookieDomain': self.appConfig.settings.analytics.gaCookieDomain,
      });
      self.active = true;
    })(window, document, 'script', gaURL, this.gaCode);
  }


  sendEvent(event: AnalyticsEventModel) {
    if (!this.active) {
      return;
    }
    window[this.gaCode]('send', {
      hitType: 'event',
      eventCategory: event.category,
      eventAction: event.action,
      eventLabel: event.label
    });
  }


  setDimension(dimension: string) {
    if (!this.active) {
      return;
    }
    window[this.gaCode]('set', 'dimension1', dimension);
  }


  trackPageView(page: string) {
    if (!this.active) {
      return;
    }
    window[this.gaCode]('set', 'page', page);
    window[this.gaCode]('send', 'pageview');
  }
}
