import { ServiceCandidateModel } from './service-candidate.model';

export class ServiceCategoryModel {
  constructor(
    public id: number,
    public name: string,
    public categories?: ServiceCategoryModel[],
    public services?: ServiceCandidateModel[],
    public details?: string,
  ) {}
}
