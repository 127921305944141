import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { StopClickPropagationDirective } from './directives/stop-click-propagation.directive';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { BtnBackComponent } from './components/btn-back/btn-back.component';
import { AppointmentComponent } from './components/appointment/appointment.component';
import { ErrorListComponent } from './components/error-list/error-list.component';
import { CustomDateAdapter } from './material-ui/custom-date-apapter';
import { SvgComponent } from './components/svg/svg.component';
import { GmapsAutocompleteComponent } from './components/gmaps-autocomplete/gmaps-autocomplete.component';
import { SelectOnFocusDirective } from './directives/select-on-focus.directive';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  declarations: [
    BtnBackComponent,
    StopClickPropagationDirective,
    AppointmentComponent,
    ErrorListComponent,
    GmapsAutocompleteComponent,
    SelectOnFocusDirective,
    SvgComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    AppointmentComponent,
    BtnBackComponent,
    ErrorListComponent,
    GmapsAutocompleteComponent,
    SelectOnFocusDirective,
    StopClickPropagationDirective,
    SvgComponent,
  ],
  providers: [
    {provide: DateAdapter, useClass: CustomDateAdapter }
  ]
})
export class SharedModule { }
