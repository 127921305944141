import { Component, OnInit } from '@angular/core';
import { AppConfig } from '../../../app.config';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';


@Component({
  selector: 'se-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss']
})
export class LanguageSwitchComponent implements OnInit {
  active: string;
  languages: string[];

  constructor(private translate: TranslateService, private router: Router, private appConfig: AppConfig) {
    this.languages = this.appConfig.settings.language.list;
    this.active = this.translate.currentLang;
    this.translate.onLangChange.subscribe((change) => {
      this.active = change.lang;
    });
  }

  ngOnInit() {
  }


  setLanguage(newLanguage) {
    this.translate.use(newLanguage);
    this.router.navigate(['/booking']);
  }
}
