import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { ModalComponent } from './components/modal/modal.component';
import { httpInterceptorProviders } from './interceptors';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    RouterModule
  ],
  declarations: [
    PageNotFoundComponent,
    HeaderComponent,
    FooterComponent,
    LanguageSwitchComponent,
    LoadingIndicatorComponent,
    ModalComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LoadingIndicatorComponent,
    ModalComponent,
  ],
  providers: [
    httpInterceptorProviders
  ]
})
export class CoreModule { }
