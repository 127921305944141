import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ServiceCandidateModel } from '../../../core/models/service-candidate.model';


@Component({
  selector: 'se-service-item',
  templateUrl: './service-item.component.html',
  styleUrls: ['./service-item.component.scss']
})
export class ServiceItemComponent implements OnInit {
  @Input() active: boolean;
  @Input() service: ServiceCandidateModel;
  @Output() setService = new EventEmitter<ServiceCandidateModel>();
  showMore = false;
  hasMore = false;

  constructor() {}

  ngOnInit() {
    this.hasMore = this.service.details.trim() !== '' && this.service.details.trim() !== this.service.name.trim();
  }

  selectService() {
    this.setService.emit(this.service);
  }

  toggleInfo() {
    this.showMore = !this.showMore;
  }
}
