import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AppConfig } from '../../../app.config';
import { FormControl } from '@angular/forms';
import { GmapsService } from '../../../core/services/gmaps.service';
import { Observable } from 'rxjs';

declare var google: any;

@Component({
  selector: 'se-gmaps-autocomplete',
  templateUrl: './gmaps-autocomplete.component.html',
  styleUrls: ['./gmaps-autocomplete.component.scss']
})
export class GmapsAutocompleteComponent implements OnInit {
  @Input() searchText$: Observable<string>;
  @Output() setLocation = new EventEmitter();
  searchControl: FormControl;

  @ViewChild('search')
  searchElementRef: ElementRef;

  constructor(private ngZone: NgZone, private gmapsService: GmapsService, private appConfig: AppConfig) { }

  ngOnInit() {
    this.searchControl = new FormControl();

    this.gmapsService.loadingPromise.then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        componentRestrictions: { country: this.appConfig.settings.maps.countries },
        fields: ['place_id'],
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.setLocation.emit(place);
        });
      });
    });

    this.searchText$.subscribe((text) => {
      this.searchControl.setValue(text);
    });
  }
}
