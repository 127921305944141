import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppointmentModel } from '../models/appointment.model';
import { AppConfig } from '../../app.config';
import { UserModel } from '../models/user.model';
import { Util } from '../../app.util';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseApiURL: string;
  private publicApiURL: string;
  private privateApiURL: string;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
  };
  private locale: string;


  constructor(private http: HttpClient, private appConfig: AppConfig) {
    this.baseApiURL = this.appConfig.settings.api.base;
    this.publicApiURL = `${this.baseApiURL}${this.appConfig.settings.api.public}`;
    this.privateApiURL = `${this.baseApiURL}${this.appConfig.settings.api.private}`;
    this.locale = this.appConfig.settings.language.default;
  }


  configApiServiceLocale(locale: string) {
    this.locale = locale;
  }

  addUserDataToPublicBooking(appointmentID: string, user: UserModel, privacy: boolean) {
    // form-data
    const body = new FormData();
    body.append('appointmentId', appointmentID);
    body.append('genderType', 'UNKNOWN');
    body.append('firstName', user.firstName);
    body.append('name', user.lastName);
    body.append('mobile', '');
    body.append('email', user.email);
    body.append('privacy', privacy ? 'yes' : 'no');
    body.append('webBooking', 'yes');
    body.append('terms', 'yes');
    body.append('locale', this.locale);
    return this.http.post(`${this.publicApiURL}persistCustomerData`, body);
  }

  finalizeBookingPublic(appointmentID, participantID) {
    // form-data
    const body = new FormData();
    body.append('appointmentId', appointmentID);
    body.append('appointmentParticipantId', participantID);
    body.append('locale', this.locale);
    return this.http.post(`${this.publicApiURL}finalizeBooking`, body);
  }

  getAllTimesForDay(locationID, serviceID, date) {
    // x-www-form-urlencoded
    const body = new HttpParams()
      .set('servicerGroupId', locationID)
      .set('selectedProducts', serviceID)
      .set('bookingGroups[0]', '0')
      .set('offset', Util.createApiDateFromDate(date));
    return this.http.post( `${this.publicApiURL}calcAppointmentTimes`, body, this.httpOptions);
  }

  getDates(locationID, serviceID, startDate) {
    // x-www-form-urlencoded
    const body = new HttpParams()
      .set('servicerGroupId', locationID)
      .set('selectedProducts', serviceID)
      .set('numberOfDays', '365')
      .set('bookingGroups[0]', '0')
      .set('offset', Util.createApiDateFromDate(startDate));
    return this.http.post(`${this.publicApiURL}calcAvailableAppointments`, body, this.httpOptions);
  }

  getLocations() {
    return this.http.get(`${this.publicApiURL}getServicerGroups?locale=${this.locale}`);
  }

  getServices() {
    return this.http.get(`${this.privateApiURL}question/merged?locale=${this.locale}`);
  }

  initBookingPublic(bookingState: AppointmentModel) {
    // form-data
    const body = new FormData();
    body.append('selectedProducts', bookingState.service.id.toString(10));
    body.append('servicerGroupId', bookingState.location.id.toString(10));
    body.append('date', Util.createApiDateFromDate(bookingState.date));
    body.append('time', bookingState.time.getApiTime());
    body.append('bookingSource', this.appConfig.settings.env.domain);
    body.append('successPage', `${this.appConfig.settings.env.domain}/booking/success`);
    body.append('bookingGroup[0]', '0');
    body.append('locale', this.locale);
    return this.http.post(`${this.baseApiURL}newAppointment/bookAppointment`, body);
  }

  setComment(appointmentID, comment) {
    // form-data
    const body = new FormData();
    body.append('appointmentId', appointmentID);
    body.append('appointmentParticipant.customerComment', comment);
    return this.http.post(`${this.publicApiURL}persistBasicData`, body);
  }
}
