<se-btn-back [previous]="'/booking/service'"></se-btn-back>
<h2 translate>booking.location.title</h2>
<h3 translate>booking.location.pickLocation</h3>


<form>
  <se-gmaps-autocomplete [searchText$]="searchText$" (setLocation)="geolocatePlace($event)"></se-gmaps-autocomplete>
  <button class="btn btn-icon" type="button"
          (click)="findUserPosition()" [title]="'booking.location.findPosition' | translate">
    <se-svg [type]="'position'"></se-svg>
  </button>
</form>


<ng-container *ngIf="(filteredLocations$ | async) as filteredLocations">
  <ul class="locations" *ngIf="filteredLocations.length > 0">
    <li *ngFor="let loc of filteredLocations" (click)="selectLocation(loc)" [ngClass]="{'active': isActiveLocation(loc)}">
      <div class="name">
        {{loc.name}}
        <span class="distance" [hidden]="loc.distance === undefined">({{loc.distance | number: '1.1-1'}} km)</span>
      </div>
      <div class="address">{{loc.street}} {{loc.no}}, {{loc.zip}} {{loc.city}}</div>
    </li>
  </ul>

  <div class="content-block detail-text" *ngIf="filteredLocations.length === 0">
    {{'booking.location.nothingFound' | translate }}
  </div>
</ng-container>
