<div class="wrapper" *ngIf="(visible$ | async) && (modal$ | async) as modal">
  <div class="modal">
    <h3>{{modal.title}}</h3>
    <div class="content-block">
      {{modal.message}}
    </div>
    <button class="btn btn-clear float-right" type="button" (click)="dismissModal()" translate>
      general.button.close
    </button>
  </div>
</div>
