import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { TimeModel } from '../../../core/models/time.model';
import { DataService } from '../../../core/services/data.service';
import { BookingService } from '../../services/booking.service';
import { AppointmentModel } from '../../../core/models/appointment.model';
import { LoggerService } from '../../../core/services/logger.service';



@Component({
  selector: 'se-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.scss']
})
export class DatetimeComponent implements OnInit {
  dates: object;
  minDate = new Date();
  selectedDate;
  times$: Observable<TimeModel[]>;
  private bookingState: AppointmentModel;


  constructor(
    private bookingService: BookingService,
    private dataService: DataService,
    private translate: TranslateService,
    private adapter: DateAdapter<any>,
    private logger: LoggerService,
    private route: ActivatedRoute
  ) {
    const today = new Date();
    this.selectedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    this.isDateValid = this.isDateValid.bind(this);
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.bookingState = data.bookingState;

      this.dataService.getDates(this.bookingState.location.id, this.bookingState.service.id, this.selectedDate).subscribe((dates) => {
        this.dates = dates;
        this.setInitialDate();
        this.times$ = this.dataService.getTimes(this.bookingState.location.id, this.bookingState.service.id, this.selectedDate);
      });
    });

    this.adapter.setLocale(this.translate.currentLang);
  }


  canAdvance() {
    return this.bookingState && this.bookingState.date !== null && this.bookingState.time !== null;
  }


  isDateValid(date) {
    return this.dates[date.getTime()] !== undefined;
  }


  isTimeChecked(time) {
    return time.available
      && this.bookingState.time !== null
      && time.hours === this.bookingState.time.hours
      && time.minutes === this.bookingState.time.minutes;
  }


  setDate(event: MatDatepickerInputEvent<Date>) {
    this.bookingService.setDate(event.value);
    this.bookingService.setTime(null);
    this.times$ = this.dataService.getTimes(this.bookingState.location.id, this.bookingState.service.id, event.value);
  }


  setTime(time: TimeModel) {
    if (time.available) {
      this.bookingService.setTime(time);
    }
  }


  private setInitialDate() {
    if (this.bookingState.date !== null && this.isDateValid(this.bookingState.date)) {
      // Set date from booking state
      this.selectedDate = this.bookingState.date;
    } else if (this.isDateValid(this.selectedDate)) {
      // If today is a valid date, use today
      this.bookingService.setDate(this.selectedDate);
    } else {
      // If today is not valid, find earliest possible date
      this.logger.debug(this.dates);
      const firstPossibleDate = Object.keys(this.dates).reduce((prev, current) => prev === null || current < prev ? current : prev, null);
      if (firstPossibleDate !== null) {
        const newDate = new Date();
        newDate.setTime(parseInt(firstPossibleDate, 10));
        this.selectedDate = newDate;
      }
      this.bookingService.setDate(this.selectedDate);
    }
  }
}
