import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, merge, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { DataService } from '../../../core/services/data.service';
import { BookingService } from '../../services/booking.service';
import { GeolocationService } from '../../../core/services/geolocation.service';
import { AppointmentModel } from '../../../core/models/appointment.model';
import { PositionModel } from '../../../core/models/position.model';
import { LocationModel } from '../../../core/models/location.model';


@Component({
  selector: 'se-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit, OnDestroy {
  filteredLocations$;
  searchText$;
  private bookingState: AppointmentModel;
  private locationsSub;
  private placeSubject: Subject<PositionModel>;
  private bookingPositionSubject$: Subject<PositionModel>;


  constructor(
    private dataService: DataService,
    private bookingService: BookingService,
    private geolocationService: GeolocationService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.placeSubject = new Subject<PositionModel>();
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      let locationName = '';
      this.bookingState = data.bookingState;
      this.locationsSub = this.dataService.getLocations();
      let bookedPosition;
      if (this.bookingState.location) {
        bookedPosition = new PositionModel(this.bookingState.location.latitude, this.bookingState.location.longitude);
        locationName = `${this.bookingState.location.street}, ${this.bookingState.location.zip} ${this.bookingState.location.city}`;
      }
      this.bookingPositionSubject$ = new BehaviorSubject<PositionModel>(bookedPosition);
      this.searchText$ = new BehaviorSubject(locationName);
    });

    this.filteredLocations$ = combineLatest([
      merge(
        this.placeSubject,
        this.geolocationService.userPosition$,
        this.bookingPositionSubject$,
      ),
      this.locationsSub.pipe(
        map((locations: LocationModel[]) =>
          BookingService.filterLocationsByService(this.bookingState.serviceCandidate.serviceMap, locations),
        ),
      ),
    ]).pipe(
      map((result) => GeolocationService.calculateDistances(result)),
      map((locations: LocationModel[]) => BookingService.sortLocationsByName(locations)),
      map((locations: LocationModel[]) => BookingService.sortLocationsByDistance(locations)),
      tap((locations: LocationModel[]) => this.setClosestLocation(locations)),
    );
  }

  ngOnDestroy() {
  }


  findUserPosition() {
    this.geolocationService.getUserPosition().then((locationText) => {
      this.searchText$.next(locationText);
    });
  }


  geolocatePlace(place) {
    if (place.place_id) {
      this.geolocationService.geocodePlace(place.place_id).toPromise().then((result) => {
        this.placeSubject.next(result);
      });
    } else if (place.name) {
      this.geolocationService.geocodeAddress(place.name).toPromise().then((result) => {
        this.placeSubject.next(result);
      });
    }
  }


  isActiveLocation(location: LocationModel) {
    return this.bookingState.location && location.id === this.bookingState.location.id;
  }


  selectLocation(loc) {
    this.bookingService.setLocation(loc);
    this.router.navigate(['/booking/datetime']);
  }


  private setClosestLocation(locations: LocationModel[]) {
    const closestLocation = GeolocationService.getClosestLocation(locations, this.bookingState.serviceCandidate.serviceMap);
    if (closestLocation !== null) {
      this.bookingService.setLocation(closestLocation);
    }
  }
}
