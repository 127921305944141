export class LocationModel {
  distance: number;

  constructor(
    public id: number,
    public name: string,
    public street?: string,
    public no?: string,
    public zip?: string,
    public city?: string,
    public latitude?: number,
    public longitude?: number
  ) {}

  setDistance(dist) {
    this.distance = dist;
  }
}
