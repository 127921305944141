<se-btn-back [previous]="'/booking/location'"></se-btn-back>
<h2 translate>booking.datetime.title</h2>

<h3 translate>booking.datetime.pickDate</h3>
<div class="date-picker" *ngIf="dates" (click)="dp3.open()">
  <mat-datepicker-toggle matSuffix [for]="dp3" [disableRipple]="true"></mat-datepicker-toggle>
  <input matInput placeholder="Input disabled" disabled
         [matDatepicker]="dp3" [matDatepickerFilter]="isDateValid" [min]="minDate" [value]="selectedDate"
         (dateChange)="setDate($event)" />
  <mat-datepicker #dp3 disabled="false"></mat-datepicker>
</div>

<h3 translate>booking.datetime.pickTime</h3>
<div class="time-list">
  <div class="input-group" *ngFor="let time of times$ | async" [ngClass]="{'disabled': !time.available}">
    <mat-radio-button [labelPosition]="'before'" [disableRipple]="true"
                      [disabled]="!time.available" (click)="setTime(time)" [checked]="isTimeChecked(time)">
      {{time.hours | number: '2.0-0'}}{{'general.details.timeDelimiter' | translate}}{{time.minutes | number: '2.0-0'}} {{'booking.datetime.time' | translate}}
    </mat-radio-button>
  </div>
</div>


<button type="button" class="btn" [disabled]="!canAdvance()" [routerLink]="['/booking/summary']">
  {{'general.button.advance' | translate }}
</button>
