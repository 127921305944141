<li class="service"
    (click)="selectService()"
    [ngClass]="{'active': active}"
    seStopClickPropagation
    [title]="'booking.service.select' | translate: {serviceName: service.name}">
  <div>{{service.name}}</div>
  <div class="info" [ngClass]="{'open': showMore}" *ngIf="hasMore">
    {{service.details}}
    <span class="btn-more" (click)="toggleInfo()" seStopClickPropagation
          [title]="'booking.service.showMoreTitle' | translate: {serviceName: service.name}">
      {{showMore ? ('booking.service.less' | translate) : ('booking.service.more' | translate)}}
    </span>
  </div>
</li>
