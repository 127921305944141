import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BookingRoutingModule } from './booking-routing.module';
import { LocationComponent } from './components/location/location.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { ServiceComponent } from './components/service/service.component';
import { DatetimeComponent } from './components/datetime/datetime.component';
import { SummaryComponent } from './components/summary/summary.component';
import { SuccessComponent } from './components/success/success.component';
import { ServiceCategoryComponent } from './components/service-category/service-category.component';
import { SharedModule } from '../shared/shared.module';
import { ServiceItemComponent } from './components/service-item/service-item.component';
import { BookingService } from './services/booking.service';
import { BookingStateResolverService } from './services/booking-state-resolver.service';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BookingRoutingModule
  ],
  declarations: [
    LocationComponent,
    WelcomeComponent,
    ServiceComponent,
    DatetimeComponent,
    SummaryComponent,
    SuccessComponent,
    ServiceCategoryComponent,
    ServiceItemComponent,
  ],
  providers: [
    BookingService,
    BookingStateResolverService
  ]
})
export class BookingModule { }
