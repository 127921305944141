<div class="info" *ngIf="appointment.location">
  <div class="name">{{appointment.location.name}}</div>
  <div class="detail-text">
    {{appointment.location.street}} {{appointment.location.no}},
    {{appointment.location.zip}} {{appointment.location.city}}
  </div>
</div>

<div class="info">
  <div class="name" *ngIf="appointment.service">
    {{appointment.service.name}}
  </div>
  <div class="detail-text" *ngIf="appointment.date">
    {{'general.appointment.date' | translate}}
    {{appointment.date | date: 'EEEE, dd. LLLL yyyy' : 'CET' : lang}}
  </div>
  <div class="detail-text" *ngIf="appointment.time">
    {{'general.appointment.time' | translate}}
    {{appointment.time.hours | number: '2.0-0'}}{{'general.details.timeDelimiter'| translate}}{{appointment.time.minutes | number: '2.0-0'}}
  </div>
  <div class="detail-text" *ngIf="appointment.service && appointment.service.duration">
    {{'general.appointment.duration' | translate}}
    {{appointment.service.duration}}
  </div>
</div>

<div class="info" *ngIf="appointment.service && appointment.service.details">
  <span class="btn-more" (click)="toggleDetails()" *ngIf="appointment.service.openDetails === false"
        [title]="'booking.summary.showMoreTitle' | translate: {serviceName: appointment.service.name}">
      {{showDetails ? ('booking.summary.less' | translate) : ('booking.summary.more' | translate)}}
  </span>
  <div class="detail-text long-description" [ngClass]="{'open': showDetails}" [innerHTML]="appointment.service.details"></div>
</div>
