import { Injectable } from '@angular/core';

import Logger from '@itds/logger';
import { AppConfig } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private readonly logger: Logger;

  constructor(private appConfig: AppConfig) {
    this.logger = Logger({
      meta: {
        application: this.appConfig.settings.app.name,
        domain: this.appConfig.settings.env.domain,
        environment: this.appConfig.settings.env.name,
      },
      production: this.appConfig.settings.env.production,
      transports: {
        console: {
          active: this.appConfig.settings.logging.console.active,
          levelDev: this.appConfig.settings.logging.console.level,
          levelProd: this.appConfig.settings.logging.console.level,
        },
        domElem: {
          active: false,
        },
        http: {
          active: this.appConfig.settings.logging.http.active,
          levelDev: this.appConfig.settings.logging.http.level,
          levelProd: this.appConfig.settings.logging.http.level,
          port: this.appConfig.settings.logging.http.port,
          ssl: this.appConfig.settings.logging.http.ssl,
        },
      },
    });
  }


  public debug(message: any): void {
    this.logger.debug(message);
  }


  public error(message: any): void {
    this.logger.error(message);
  }


  public info(message: any): void {
    this.logger.info(message);
  }


  public silly(message: any): void {
    this.logger.silly(message);
  }


  public verbose(message: any): void {
    this.logger.verbose(message);
  }


  public warn(message: any): void {
    this.logger.warn(message);
  }
}
