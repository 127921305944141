<ng-container [ngSwitch]="type">
  <svg *ngSwitchCase="'home'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M0,0H24V24H0Z" fill="none" />
    <path d="M12,5.69l5,4.5V18H15V12H9v6H7V10.19l5-4.5M12,3,2,12H5v8h6V14h2v6h6V12h3Z" fill="currentColor" />
  </svg>
  <svg *ngSwitchCase="'success'" xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72">
    <path d="M0,0H72V72H0Z" fill="none"/>
    <path d="M36,2A34,34,0,1,0,70,36,34.012,34.012,0,0,0,36,2Zm0,61.2A27.2,27.2,0,1,1,63.2,36,27.236,27.236,0,0,1,36,63.2ZM51.606,20.972,29.2,43.378l-8.806-8.772L15.6,39.4,29.2,53,56.4,25.8Z" fill="currentColor"/>
  </svg>
  <svg *ngSwitchCase="'remove'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <g transform="translate(185 -16)">
      <g transform="translate(-184.993 15.99)">
        <rect width="38.703" height="2.5" transform="translate(-0.007 28.378) rotate(-45)" fill="currentColor" />
        <rect width="38.703" height="2.5" transform="translate(27.372 30.01) rotate(-135)" fill="currentColor" />
      </g>
    </g>
  </svg>
  <svg *ngSwitchCase="'position'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-hidden="true" focusable="false" role="img">
    <path fill="currentColor" d="M504 240h-56.81C439.48 146.76 365.24 72.52 272 64.81V8c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v56.81C146.76 72.52 72.52 146.76 64.81 240H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h56.81c7.71 93.24 81.95 167.48 175.19 175.19V504c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-56.81c93.24-7.71 167.48-81.95 175.19-175.19H504c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zM256 416c-88.22 0-160-71.78-160-160S167.78 96 256 96s160 71.78 160 160-71.78 160-160 160zm0-256c-53.02 0-96 42.98-96 96s42.98 96 96 96 96-42.98 96-96-42.98-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64z"></path>
  </svg>
</ng-container>
