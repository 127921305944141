import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ServiceCategoryModel } from '../../../core/models/service-category.model';
import { BookingService } from '../../services/booking.service';
import { AppointmentModel } from '../../../core/models/appointment.model';
import { ServiceCandidateModel } from '../../../core/models/service-candidate.model';


@Component({
  selector: 'se-service-category',
  templateUrl: './service-category.component.html',
  styleUrls: ['./service-category.component.scss']
})
export class ServiceCategoryComponent implements OnInit {
  @Input() bookingState: AppointmentModel;
  @Input() category: ServiceCategoryModel;
  @Input() main: boolean;
  @Input() startServiceID: number;
  hasSubCats: boolean;
  hasServices: boolean;
  servicesClosed: boolean;


  constructor(private bookingService: BookingService, private router: Router) { }

  ngOnInit() {
    this.hasSubCats = this.category.categories.length > 0;
    this.hasServices = this.category.services.length > 0;
    this.checkForStartingItem();
    this.servicesClosed = !this.main && !this.hasActiveItem();
  }


  isItemActive(serviceID) {
    return this.bookingState && this.bookingState.serviceCandidate !== null && this.bookingState.serviceCandidate.id === serviceID;
  }


  selectService(candidate: ServiceCandidateModel) {
    this.bookingService.setServiceCandidate(candidate);
    this.router.navigate(['/booking/location']);
  }


  toggleServices() {
    if (!this.main) {
      this.servicesClosed = !this.servicesClosed;
    }
  }


  private checkForStartingItem() {
    if (this.hasServices) {
      const startService = this.category.services.find((service) => service.id === this.startServiceID);
      if (startService) {
        this.bookingService.setServiceCandidate(startService);
      }
    }
  }


  private hasActiveItem() {
    return this.hasServices
      && this.bookingState
      && this.bookingState.serviceCandidate !== null
      && this.category.services.some((service) => service.id === this.bookingState.serviceCandidate.id);
  }
}
