export class TimeModel {
  constructor(
    public hours: number,
    public minutes: number,
    public available: boolean,
  ) {}

  getApiTime() {
    return `${this.hours < 10 ? '0' + this.hours : this.hours}:${this.minutes < 10 ? '0' + this.minutes : this.minutes}`;
  }
}
