import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ModalContentModel } from '../models/modal-content.model';
import { LoggerService } from './logger.service';


@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modalContent: ModalContentModel[] = [];
  private contentSubject: BehaviorSubject<ModalContentModel>;
  private visibleSubject: BehaviorSubject<boolean>;


  constructor(private logger: LoggerService, private translate: TranslateService) {
    this.visibleSubject = new BehaviorSubject(false);
    this.contentSubject = new BehaviorSubject(null);
  }


  dismissModal() {
    this.logger.silly('Dismissing modal');
    this.modalContent.shift();
    if (this.modalContent.length === 0) {
      this.visibleSubject.next(false);
    } else {
      this.logger.debug('Another message is waiting');
      this.contentSubject.next(this.modalContent[0]);
      this.logger.debug(this.modalContent[0]);
    }
  }


  getModalContent(): Observable<ModalContentModel> {
    return this.contentSubject.asObservable();
  }


  getVisibility(): Observable<boolean> {
    return this.visibleSubject.asObservable();
  }


  openModal(title: string, message: string) {
    this.logger.silly(`Opening modal: ${title}`);
    this.modalContent.push(new ModalContentModel(title, message));
    if (this.modalContent.length === 1) {
      this.contentSubject.next(this.modalContent[0]);
    }
    this.visibleSubject.next(true);
  }

  openModalFromKey(key) {
    forkJoin([
      this.translate.get(`modal.${key}.title`),
      this.translate.get(`modal.${key}.message`),
    ]).pipe(
      first()
    ).subscribe((result) => {
      this.openModal(result[0], result[1]);
    });
  }
}
