<li [ngClass]="{'main': main, 'open':!servicesClosed && !main, 'sub-cat': !main && hasServices}" (click)="toggleServices()">
  <div class="wrapper">
    <div class="title">{{category.name}}</div>
  </div>

  <ul *ngIf="hasSubCats">
    <se-service-category
      *ngFor="let subCat of category.categories"
      [main]="false" [category]="subCat" [bookingState]="bookingState" [startServiceID]="startServiceID">
    </se-service-category>
  </ul>

  <ul class="services" [ngClass]="{'closed': servicesClosed}" *ngIf="hasServices">
    <se-service-item
      *ngFor="let service of category.services"
      [active]="isItemActive(service.id)" [service]="service"
      (setService)="selectService($event)">
    </se-service-item>
  </ul>
</li>
