import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { BookingService } from '../../services/booking.service';
import { AppointmentModel } from '../../../core/models/appointment.model';
import { UserModel } from '../../../core/models/user.model';


@Component({
  selector: 'se-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  bookingState: AppointmentModel;
  comment = new FormControl('');
  errors: string[] = [];
  registerForm = new FormGroup({
    firstName: new FormControl('', [
      Validators.required,
    ]),
    lastName: new FormControl('', [
      Validators.required,
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
    privacy: new FormControl(false, [
      Validators.requiredTrue,
    ]),
  });


  constructor(
    private bookingService: BookingService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.bookingState = data.bookingState;
      this.comment.setValue(this.bookingState.comment);
    });
  }


  addComment() {
    this.bookingService.setComment(this.comment.value);
  }


  isBookingPossible(): boolean {
    return this.registerForm.valid;
  }


  submitRequest() {
    const user = new UserModel(
      this.registerForm.get('firstName').value,
      this.registerForm.get('lastName').value,
      this.registerForm.get('email').value,
      '',
    );
    this.bookingService.reserveAppointment(user, this.registerForm.get('privacy').value).then((result) => {
      if (result) {
        this.bookingService.submitBookingRequest();
      }
    });
  }
}
