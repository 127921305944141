import { Component, OnInit } from '@angular/core';

import { ModalService } from '../../services/modal.service';
import { ModalContentModel } from '../../models/modal-content.model';
import { Observable } from 'rxjs';


@Component({
  selector: 'se-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  modal$: Observable<ModalContentModel>;
  visible$: Observable<boolean>;

  constructor(private modalService: ModalService) {
    this.visible$ = this.modalService.getVisibility();
    this.modal$ = this.modalService.getModalContent();
  }

  ngOnInit() {
  }

  dismissModal() {
    this.modalService.dismissModal();
  }
}
