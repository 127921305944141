import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';

import { LocationModel } from '../../core/models/location.model';
import { ServiceModel } from '../../core/models/service.model';
import { ApiService } from '../../core/services/api.service';
import { TimeModel } from '../../core/models/time.model';
import { AppointmentModel } from '../../core/models/appointment.model';
import { StorageService } from '../../core/services/storage.service';
import { LoggerService } from '../../core/services/logger.service';
import { ModalService } from '../../core/services/modal.service';
import { Modal } from '../../core/enums/modal.enum';
import { ServiceCandidateModel } from '../../core/models/service-candidate.model';
import { UserModel } from '../../core/models/user.model';


@Injectable()
export class BookingService {
  private bookingState = null;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private storageService: StorageService,
    private logger: LoggerService,
    private modalService: ModalService,
  ) {}


  static filterLocationsByService(serviceMap, locations: LocationModel[]): LocationModel[] {
    return locations.filter((location: LocationModel) => {
      return serviceMap[location.id] !== undefined;
    });
  }


  static sortLocationsByName(locations: LocationModel[]): LocationModel[] {
    return locations.sort((a, b) => {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    });
  }


  static sortLocationsByDistance(locations: LocationModel[]): LocationModel[] {
    return locations.sort((a, b) => {
      if (a.distance === undefined) { return 1; }
      if (b.distance === undefined) { return -1; }
      if (a.distance === 0) { return -1; }
      if (b.distance === 0) { return 1; }
      if (a.distance < b.distance) { return -1; }
      if (a.distance > b.distance) { return 1; }
      return 0;
    });
  }


  getBookingState(): Promise<AppointmentModel> {
    return new Promise((resolve) => {
      if (this.bookingState !== null) {
        this.logger.debug(this.bookingState);
        resolve(this.bookingState);
      } else {
        const stateFromStorage = this.loadBookingState();
        if (stateFromStorage !== null) {
          this.bookingState = stateFromStorage;
        } else {
          this.bookingState = new AppointmentModel(null, null, null, null, null, '');
          this.logger.debug('No booking state in storage');
        }
        this.logger.debug(this.bookingState);
        resolve(this.bookingState);
      }
    });
  }


  reserveAppointment(user: UserModel, privacy: boolean): Promise<boolean> {
    return new Promise((resolve) => {
      this.apiService.initBookingPublic(this.bookingState).toPromise().then((result) => {
        this.logger.debug(result);
        if (result['result'] === 'OK') {
          this.addAppointmentIDsToState(result['appointmentId'], result['appointmentParticipantId']);
          forkJoin([
            this.apiService.addUserDataToPublicBooking(result['appointmentId'], user, privacy),
            this.apiService.setComment(this.bookingState.appointmentID, this.bookingState.comment),
          ]).toPromise().then(() => {
            resolve(true);
          }).catch((error) => {
            this.logger.error('API error while adding comment or user data to appointment');
            this.logger.error(error);
            resolve(false);
          });
        } else {
          this.modalService.openModalFromKey(Modal.BookingGiven);
          resolve(false);
        }
      }).catch((error) => {
        this.logger.error('API error while reserving appointment');
        this.logger.error(error);
        resolve(false);
      });
    });
  }


  setComment(comment: string) {
    this.bookingState.comment = comment;
    this.saveBookingState();
  }


  setDate(date: Date) {
    this.bookingState.date = date;
    this.saveBookingState();
  }


  setLocation(location: LocationModel) {
    this.bookingState.location = location;
    this.saveBookingState();
    this.setServiceByLocationId(location.id);
  }


  setService(service: ServiceModel) {
    this.bookingState.service = service;
    this.saveBookingState();
  }


  setServiceCandidate(candidate: ServiceCandidateModel) {
    this.bookingState.serviceCandidate = candidate;
    this.saveBookingState();
  }


  setTime(time: TimeModel) {
    this.bookingState.time = time;
    this.saveBookingState();
  }


  submitBookingRequest() {
    return new Promise((resolve) => {
      return this.apiService.finalizeBookingPublic(this.bookingState.appointmentID, this.bookingState.participantID)
        .toPromise().then((result) => {
          if (result['result'] === 'OK') {
            this.clearBookingState();
            this.router.navigate(['/booking/success']);
            resolve(true);
          } else {
            this.logger.error('Error while finalizing booking');
            this.modalService.openModalFromKey(Modal.BookingError);
            resolve(false);
          }
        }).catch((error) => {
          this.logger.error('API error while finalizing booking');
          this.logger.error(error);
          resolve(false);
        });
    });
  }


  private addAppointmentIDsToState(appointmentID: string, participantID?: string) {
    this.bookingState.appointmentID = appointmentID;
    this.bookingState.participantID = participantID || '';
    this.saveBookingState();
  }


  private clearBookingState() {
    this.bookingState = new AppointmentModel(null, null, null, null, null, '');
    this.storageService.remove('bookingState');
  }


  private loadBookingState(): AppointmentModel | null {
    const state = this.storageService.retrieve('bookingState');
    if (state === null) {
      return state;
    }

    let serviceCandidate = null;
    if (state.serviceCandidate) {
      // The serviceMap should be serialized because it contains ServiceModels.
      // As long as ServiceModel only contains simple data types, this
      // shouldn't become a problem
      serviceCandidate = new ServiceCandidateModel(
        state.serviceCandidate.id,
        state.serviceCandidate.name,
        state.serviceCandidate.details,
        state.serviceCandidate.serviceMap,
      );
    }

    let service = null;
    if (state.service) {
      service = new ServiceModel(
        state.service.id,
        state.service.name,
        state.service.details,
        state.service.openDetails,
        state.service.duration
      );
    }

    let location = null;
    if (state.location) {
      location = new LocationModel(
        state.location.id,
        state.location.name,
        state.location.street,
        state.location.no,
        state.location.zip,
        state.location.city,
        state.location.latitude,
        state.location.longitude
      );
    }

    let date = null;
    if (state.date) {
      date = new Date(state.date);
    }

    let time = null;
    if (state.time) {
      time = new TimeModel(
        state.time.hours,
        state.time.minutes,
        state.time.available
      );
    }

    return new AppointmentModel(serviceCandidate, location, service, date, time, state.comment, state.appointmentID, state.participantID);
  }


  private saveBookingState() {
    this.storageService.store('bookingState', this.bookingState);
  }


  private setServiceByLocationId(id) {
    const service = this.bookingState.serviceCandidate.serviceMap[id];
    if (service) {
      this.bookingState.service = service;
      this.saveBookingState();
    } else {
      this.logger.error(`No service found for location with id: ${id}`);
    }
  }
}
