<header>
  <div class="float-right">
    <se-language-switch></se-language-switch>
  </div>

  <a routerLink="">
    <h1>
      Experts
    </h1>
  </a>
</header>
