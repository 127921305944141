import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  currentlyLoading: string[];
  loadingSubject: BehaviorSubject<boolean>;

  constructor() {
    this.currentlyLoading = [];
    this.loadingSubject = new BehaviorSubject(false);
  }


  finishedLoading(id) {
    const finishedPos = this.currentlyLoading.indexOf(id);
    this.currentlyLoading.splice(finishedPos, 1);
    this.checkLoadingQueue();
  }


  isLoading(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }


  startLoading(id) {
    this.currentlyLoading.push(id);
    this.checkLoadingQueue();
  }


  private checkLoadingQueue() {
    // Debounce loading queue check to prevent loading flicker on fast loads
    setTimeout(() => {
      if (this.currentlyLoading.length > 0) {
        this.loadingSubject.next(true);
      } else {
        this.loadingSubject.next(false);
      }
    }, 100);
  }
}
