import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';

import { AppointmentModel } from '../../core/models/appointment.model';
import { BookingService } from './booking.service';
import { LoggerService } from '../../core/services/logger.service';



@Injectable()
export class BookingStateResolverService implements Resolve<AppointmentModel> {

  constructor(private bookingService: BookingService, private router: Router, private logger: LoggerService) { }

  resolve(route: ActivatedRouteSnapshot): Promise<AppointmentModel> {
    return this.bookingService.getBookingState().then((bookingState: AppointmentModel) => {
      switch (route.url[0].path) {
        case 'location':
          if (bookingState.serviceCandidate !== null) {
            return bookingState;
          } else {
            this.logger.debug('Rerouting to /booking/service because service has not been picked');
            this.router.navigate(['/booking/service']);
            return undefined;
          }
        case 'datetime':
          if (bookingState.location !== null) {
            return bookingState;
          } else {
            this.logger.debug('Rerouting to /booking/location because location has not been picked');
            this.router.navigate(['/booking/location']);
            return undefined;
          }
        case 'summary':
          if (bookingState.date !== null && bookingState.time !== null) {
            return bookingState;
          } else {
            this.logger.debug('Rerouting to /booking/datetime because date or time has not been picked');
            this.router.navigate(['/booking/datetime']);
            return undefined;
          }
        default:
          return bookingState;
      }
    });
  }
}
