<footer>
  <button id="home-button" class="btn btn-icon" type="button" [routerLink]="['']" [title]="'footer.button.home' | translate">
    <se-svg [type]="'home'"></se-svg>
  </button>
  {{'footer.company' | translate}}
  <div class="links">
    <a href="{{'footer.contactURL' | translate}}" translate>footer.contactLink</a>
    <a href="{{'footer.tosURL' | translate}}" target="_blank" rel="noopener" translate>footer.tosLink</a>
    <a href="{{'footer.privacyURL' | translate}}" target="_blank" rel="noopener" translate>footer.privacyLink</a>
    <a href="{{'footer.imprintURL' | translate}}" target="_blank" rel="noopener" translate>footer.imprintLink</a>
  </div>
</footer>
