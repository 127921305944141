import { Injectable } from '@angular/core';
import { GeocoderResult, MapsAPILoader } from '@agm/core';
import { Observable } from 'rxjs';

import { PositionModel } from '../models/position.model';
import { AppConfig } from '../../app.config';
import { LoggerService } from './logger.service';

declare var google: any;


@Injectable({
  providedIn: 'root'
})
export class GmapsService {
  loadingPromise;


  constructor(private __loader: MapsAPILoader, private logger: LoggerService, private appConfig: AppConfig) {
    this.loadingPromise = this.__loader.load().then(() => {
      this.logger.debug('Google Maps API loaded');
    });
  }


  getAddressByLatLng(position: PositionModel): Observable<GeocoderResult> {
    return new Observable<GeocoderResult>(observer => {
      try {
        const request = { region: this.appConfig.settings.maps.region };
        this.loadingPromise.then(() => {
          request['latLng'] = new google.maps.LatLng(position.latitude, position.longitude);
          this.geocode(request, observer);
        });
      } catch (error) {
        this.logger.error(`Get address by LatLng failed: ${error}`);
        observer.complete();
      }
    });
  }


  getAddressByPlaceId(placeId: string): Observable<GeocoderResult> {
    return new Observable<GeocoderResult>(observer => {
      if (!placeId) {
        this.logger.error('Trying to geolocate empty place ID');
        observer.complete();
      } else {
        try {
          const request = { placeId, region: this.appConfig.settings.maps.region };
          this.loadingPromise.then(() => {
            this.geocode(request, observer);
          });
        } catch (error) {
          this.logger.error(`Get address by place id failed: ${error}`);
          observer.complete();
        }
      }
    });
  }


  getLatLng(address: string): Observable<GeocoderResult> {
    return new Observable<GeocoderResult>(observer => {
      try {
        const request = { address, region: this.appConfig.settings.maps.region };
        this.loadingPromise.then(() => {
          this.geocode(request, observer);
        });
      } catch (error) {
        this.logger.error(`Get LatLng by address failed: ${error}`);
        observer.complete();
      }
    });
  }


  private geocode(request, observer) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(request, (results: GeocoderResult[], status) => {
      this.logger.debug(results);
      if (status === google.maps.GeocoderStatus.OK) {
        results.forEach((result: GeocoderResult) => {
          observer.next(result);
        });
        observer.complete();
      } else {
        if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
          this.logger.debug('Address not found!');
        } else {
          this.logger.warn(`Geocoding failed with status: ${status}`);
        }
        observer.complete();
      }
    });
  }
}
