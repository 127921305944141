import { Component, OnInit } from '@angular/core';

import { LoadingService } from '../../services/loading.service';


@Component({
  selector: 'se-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit {
  visible = false;


  constructor(private loadingService: LoadingService) {
    this.loadingService.isLoading().subscribe((loading) => {
      this.visible = loading;
    });
  }

  ngOnInit() {
  }
}
