import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { DataService } from '../../../core/services/data.service';
import { ServiceCategoryModel } from '../../../core/models/service-category.model';
import { AppointmentModel } from '../../../core/models/appointment.model';


@Component({
  selector: 'se-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit, OnDestroy {
  bookingState: AppointmentModel;
  categories$: Observable<ServiceCategoryModel[]>;
  startService: number;
  private routeParamSub: any;


  constructor(private dataService: DataService, private route: ActivatedRoute) {
    this.categories$ = this.dataService.getServices();
  }

  ngOnInit() {
    this.routeParamSub = this.route.params.subscribe((params) => {
      this.startService = parseInt(params['serviceID'], 10);
    });
    this.route.data.subscribe((data) => {
      this.bookingState = data.bookingState;
    });
  }

  ngOnDestroy() {
    this.routeParamSub.unsubscribe();
  }
}
