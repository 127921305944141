import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LocationComponent } from './components/location/location.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { ServiceComponent } from './components/service/service.component';
import { DatetimeComponent } from './components/datetime/datetime.component';
import { SummaryComponent } from './components/summary/summary.component';
import { SuccessComponent } from './components/success/success.component';
import { BookingStateResolverService } from './services/booking-state-resolver.service';


const routes: Routes = [
  {
    path: 'booking',
    children: [
      { path: '', component: WelcomeComponent },
      { path: 'service/:serviceID', component: ServiceComponent, resolve: { bookingState: BookingStateResolverService } },
      { path: 'service', component: ServiceComponent, resolve: { bookingState: BookingStateResolverService } },
      { path: 'location', component: LocationComponent, resolve: { bookingState: BookingStateResolverService } },
      { path: 'datetime', component: DatetimeComponent, resolve: { bookingState: BookingStateResolverService } },
      { path: 'summary', component: SummaryComponent, resolve: { bookingState: BookingStateResolverService } },
      { path: 'success', component: SuccessComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BookingRoutingModule { }
