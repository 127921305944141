import { Component, Input, OnInit } from '@angular/core';

import { AppointmentModel } from '../../../core/models/appointment.model';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'se-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {
  @Input() appointment: AppointmentModel;
  lang: string;
  showDetails = false;


  constructor(private translate: TranslateService) {
    this.lang = this.translate.currentLang;
  }

  ngOnInit() {
    this.showDetails = this.appointment.service && this.appointment.service.details && this.appointment.service.openDetails;
  }


  toggleDetails() {
    this.showDetails = !this.showDetails;
  }
}
