import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { LocationModel } from '../models/location.model';
import { ApiService } from './api.service';
import { ServiceModel } from '../models/service.model';
import { LoadingService } from './loading.service';
import { ServiceCategoryModel } from '../models/service-category.model';
import { TimeModel } from '../models/time.model';
import { LoggerService } from './logger.service';
import { Util } from '../../app.util';
import { ServiceCandidateModel } from '../models/service-candidate.model';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  private dateList = {};


  constructor(
    private apiService: ApiService,
    private loadingService: LoadingService,
    private logger: LoggerService,
  ) {}


  static createServiceCandidateFromApiData(data, serviceList): ServiceCandidateModel {
    const services = {};
    Object.entries(data['serviceProducts']).forEach(([key, val]) => {
      const service = DataService.createServiceFromApiData(val, serviceList);
      if (service !== null) {
        services[key] = service;
      }
    });
    return new ServiceCandidateModel(
      parseInt(data['articleNumber'], 10),
      data['serviceProductName'],
      data['description'] ? data['description'] : '',
      services,
    );
  }


  static createServiceCategoryFromApiData(data, serviceList): ServiceCategoryModel {
    const subCats = [];
    const candidates = [];

    data['simpleDialog']['simpleDialogAnswers'].forEach((answer) => {
      if (answer['simpleDialog'] === null) {
        if (answer['articleNumber'] && answer['serviceProductName']) {
          candidates.push(DataService.createServiceCandidateFromApiData(answer, serviceList));
        }
      } else {
        subCats.push(DataService.createServiceCategoryFromApiData(answer, serviceList));
      }
    });

    return new ServiceCategoryModel(
      0,
      data['description'],
      subCats,
      candidates,
      data['description'],
    );
  }


  static createServiceFromApiData(id, services): ServiceModel {
    const service = Util.findInListById(id, services);
    if (service === undefined) {
      return null;
    }

    return new ServiceModel(
      parseInt(service.id, 10),
      service.description,
      service.longDescription,
      service.longDescriptionAlwaysShow === 1,
      service.simpleDuration
    );
  }


  getDates(locationID, serviceID, startDate): Observable<object> {
    return this.apiService.getDates(locationID, serviceID, startDate).pipe(
      map(this.transformDates.bind(this)),
    );
  }


  getLocations(): Observable<LocationModel[]> {
    return this.apiService.getLocations().pipe(
      map(this.transformLocations),
    );
  }


  getServices(): Observable<ServiceCategoryModel[]> {
    return this.apiService.getServices().pipe(
      tap(response => this.logger.debug(response)),
      map(this.transformServices),
    );
  }


  getTimes(locationID, serviceID, date: Date): Observable<TimeModel[]> {
    return this.apiService.getAllTimesForDay(locationID, serviceID, date).pipe(
      map((data) => this.transformTimes(data, date, this))
    );
  }


  private createTimesFromApiTimes(date: Date) {
    const dateTime = date.getTime();
    const times = [];
    if (this.dateList[dateTime] !== undefined) {
      this.dateList[date.getTime()].forEach((apiTime) => {
        times.push(Util.createTimeFromApiTime(apiTime));
      });
    }
    return times;
  }


  private transformDates(response) {
    const dateList = {};
    Object.keys(response['generalAvailabilities']).forEach((date) => {
      const dateString = Util.createDateFromApiDate(date);
      dateList[dateString] = true;
      this.dateList[dateString] = response['generalAvailabilities'][date].reduce((times, time) => {
        times.push(time['startDate']);
        return times;
      }, []);
    });
    return dateList;
  }


  private transformLocations(response): LocationModel[] {
    return (response as object[]).map((loc: any) => {
      return new LocationModel(
        parseInt(loc.id, 10),
        loc.name,
        loc.address.street,
        loc.address.houseNumber,
        loc.address.zip,
        loc.address.town,
        parseFloat(loc.address.latitude),
        parseFloat(loc.address.longitude)
      );
    });
  }


  private transformServices(response): ServiceCategoryModel[] {
    const categories = [];

    response['questionTree'][0]['simpleDialogAnswers'].forEach((answer) => {
      if (answer['simpleDialog'] !== null) {
        categories.push(DataService.createServiceCategoryFromApiData(answer, response['services']));
      }
    });

    return categories;
  }


  private transformTimes(response, date, context): TimeModel[] {
    const times = context.createTimesFromApiTimes(date);
    response.forEach((elem) => {
      if (times.findIndex((time) => time.getApiTime() === elem) === -1) {
        times.push(Util.createTimeFromApiTime(elem, false));
      }
    });
    return times.sort((a, b) => {
      if (a.hours < b.hours) { return -1; }
      if (a.hours > b.hours) { return 1; }
      if (a.minutes < b.minutes) { return -1; }
      if (a.minutes > b.minutes) { return 1; }
      return 0;
    });
  }
}
