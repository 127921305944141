import { ServiceModel } from './service.model';

export class ServiceCandidateModel {
  constructor(
    public id: number,
    public name: string,
    public details: string,
    public serviceMap: { [key: number]: ServiceModel },
  ) {}
}
