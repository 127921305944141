<se-header></se-header>

<div class="app-wrapper center">
  <div class="blur"></div>
  <main class="app-page center">
    <router-outlet></router-outlet>
  </main>
</div>

<se-footer></se-footer>
<se-loading-indicator></se-loading-indicator>
<se-modal></se-modal>
