import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import localeFR from '@angular/common/locales/fr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BookingModule } from './booking/booking.module';
import { AppConfig } from './app.config';
import { AgmCoreModule } from '@agm/core';


// Locales
registerLocaleData(localeDE, 'de');
registerLocaleData(localeFR, 'fr');


// App initializer functions
export function loadConfig(appConfig: AppConfig) {
  return () => appConfig.load();
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBiu0ZTYjhpkmG0rOBs8hCiV_kMj1U6Hzk',
      libraries: ['places']
    }),
    BrowserAnimationsModule,
    CoreModule,
    BookingModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: APP_INITIALIZER, useFactory: loadConfig, deps: [AppConfig], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
