import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AppConfig } from './app.config';
import { ApiService } from './core/services/api.service';
import { AnalyticsService } from './core/services/analytics.service';


@Component({
  selector: 'se-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private apiService: ApiService,
    private translate: TranslateService,
    private router: Router,
    private analytics: AnalyticsService,
    private appConfig: AppConfig,
  ) {
    // Configure analytics tracking
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.analytics.trackPageView(event.urlAfterRedirects);
        }
      });

    // Configure translation service
    this.translate.setDefaultLang(this.appConfig.settings.language.default);
    this.translate.addLangs(this.appConfig.settings.language.list);
    this.translate.onLangChange.subscribe((newLang) => {
      this.apiService.configApiServiceLocale(newLang.lang);
    });
    const browserLang = this.translate.getBrowserLang();
    if (browserLang && this.appConfig.settings.language.list.includes(browserLang)) {
      this.translate.use(browserLang);
    } else {
      this.translate.use(this.appConfig.settings.language.default);
    }
  }
}
