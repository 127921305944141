import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';


@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(private logger: LoggerService) {
    if (!window.localStorage) {
      this.logger.warn('Local storage not available');
    }
  }

  remove(key) {
    if (window.localStorage) {
      localStorage.removeItem(key);
    }
  }

  retrieve(key) {
    if (window.localStorage) {
      return JSON.parse(localStorage.getItem(key));
    } else {
      return null;
    }
  }

  store(key, data): boolean {
    if (window.localStorage) {
      localStorage.setItem(key, JSON.stringify(data));
      return true;
    } else {
      return false;
    }
  }
}
