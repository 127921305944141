import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GeocoderResult } from '@agm/core';
import { map } from 'rxjs/operators';

import { PositionModel } from '../models/position.model';
import { LocationModel } from '../models/location.model';
import { LoggerService } from './logger.service';
import { GmapsService } from './gmaps.service';



@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  userPosition$: BehaviorSubject<PositionModel>;

  constructor(private logger: LoggerService, private gmaps: GmapsService) {
    this.userPosition$ = new BehaviorSubject<PositionModel>(undefined);
  }


  static calculateDistances(result): LocationModel[] {
    const userPosition = result[0];
    const locations = result[1];
    const calculatedLocations: LocationModel[] = [];

    if (!userPosition) {
      return locations;
    }

    if (locations) {
      locations.forEach((location) => {
        if (location !== null && location.latitude && location.longitude) {
          const newLocation = location;
          const distance = GeolocationService.getDistance(userPosition, new PositionModel(location.latitude, location.longitude));
          newLocation.setDistance(distance);
          calculatedLocations.push(newLocation);
        }
      });
    }

    return calculatedLocations;
  }


  static createPositionModelFromGeolocation(location): PositionModel {
    return new PositionModel(location.coords.latitude, location.coords.longitude);
  }


  static createPositionModelFromGooglePlace(result: GeocoderResult): PositionModel {
    return new PositionModel(result.geometry.location.lat(), result.geometry.location.lng());
  }


  static deg2rad(deg) {
    return deg * (Math.PI / 180);
  }


  static getClosestLocation(locations: LocationModel[], idMap) {
    let closest = null;
    locations.forEach((location) => {
      if (idMap[location.id] && location.distance !== undefined && (closest === null || location.distance < closest.distance)) {
        closest = location;
      }
    });

    return closest;
  }


  static getDistance(start: PositionModel, end: PositionModel): number {
    const R = 6371; // Radius of the earth in km
    const dLat = GeolocationService.deg2rad(end.latitude - start.latitude);  // deg2rad below
    const dLon = GeolocationService.deg2rad(end.longitude - start.longitude);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(GeolocationService.deg2rad(start.latitude)) * Math.cos(GeolocationService.deg2rad(end.latitude)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  }



  geocodeAddress(address: string) {
    return this.gmaps.getLatLng(address).pipe(
      map((result) => GeolocationService.createPositionModelFromGooglePlace(result)),
    );
  }

  geocodePlace(placeId: string) {
    return this.gmaps.getAddressByPlaceId(placeId).pipe(
      map((result) => GeolocationService.createPositionModelFromGooglePlace(result)),
    );
  }


  getUserPosition(): Promise<string> {
    return new Promise<string>((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userPosition = GeolocationService.createPositionModelFromGeolocation(position);
            this.logger.debug('User position found');
            this.logger.debug(userPosition);
            this.userPosition$.next(userPosition);
            const revGeoSub = this.reverseGeocode(userPosition).subscribe((result) => {
              this.logger.debug(`Reverse geocoded address for user location: ${result.formatted_address}`);
              // Unsubscribe after first result, because the first result should be the most descriptive
              revGeoSub.unsubscribe();
              resolve(result.formatted_address);
            });
          },
          (error) => {
            this.errorHandler(error);
            resolve('');
          }
        );
      } else {
        this.userPosition$.next(undefined);
        this.logger.warn('Geolocation not available');
        resolve('');
      }
    });
  }


  private errorHandler(error) {
    this.logger.warn(error);
  }


  private reverseGeocode(location: PositionModel) {
    return this.gmaps.getAddressByLatLng(location);
  }
}
