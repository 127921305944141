import { LocationModel } from './location.model';
import { ServiceModel } from './service.model';
import { TimeModel } from './time.model';
import { ServiceCandidateModel } from './service-candidate.model';

export class AppointmentModel {
  constructor(
    public serviceCandidate: ServiceCandidateModel,
    public location: LocationModel,
    public service: ServiceModel,
    public date: Date,
    public time: TimeModel,
    public comment: string,
    public appointmentID?: string,
    public participantID?: string
  ) {}
}
