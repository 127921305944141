import { TimeModel } from './core/models/time.model';

export class Util  {
  static createApiDateFromDate(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
  }


  static createDateFromApiDate(dateKey: string): number {
    const dateSplit = dateKey.split('.');
    return new Date(parseInt(dateSplit[2], 10), parseInt(dateSplit[1], 10) - 1, parseInt(dateSplit[0], 10)).getTime();
  }


  static createDateFromRandomApiDate(apiDate: string): Date {
    const year = parseInt(`20${apiDate.substr(0, 2)}`, 10);
    const month = parseInt(apiDate.substr(2, 2), 10) - 1;
    const date = parseInt(apiDate.substr(4, 2), 10);
    return new Date(year, month, date);
  }


  static createTimeFromApiTime(apiTime: string, available = true): TimeModel {
    const timeSplit = apiTime.split(':');
    return new TimeModel(parseInt(timeSplit[0], 10), parseInt(timeSplit[1], 10), available);
  }


  static findInListById(id: number, list) {
    return list.find(elem => elem.id === id);
  }
}
