<se-btn-back [previous]="'/booking/datetime'"></se-btn-back>
<h2 translate>booking.summary.title</h2>


<div class="appointment">
  <h3 translate>booking.summary.appointment</h3>
  <se-appointment class="content-block" *ngIf="bookingState" [appointment]="bookingState">
  </se-appointment>

  <div class="content-block">
    <textarea (change)="addComment()" [formControl]="comment"
              [placeholder]="'general.form.commentPlaceholder' | translate">
    </textarea>
  </div>
</div>


  <div class="registration">
    <form [formGroup]="registerForm">
      <div class="content-block">
        <input type="text" formControlName="firstName" required
               [attr.aria-label]="'general.form.firstNamePlaceholder' | translate"
               [placeholder]="'general.form.firstNamePlaceholder' | translate" />
        <input type="text" formControlName="lastName" required
               [attr.aria-label]="'general.form.lastNamePlaceholder' | translate"
               [placeholder]="'general.form.lastNamePlaceholder' | translate" />
        <input type="email" formControlName="email" required
               [attr.aria-label]="'general.form.emailPlaceholder' | translate"
               [placeholder]="'general.form.emailPlaceholder' | translate" />
      </div>

      <div class="input-group content-block">
        <mat-checkbox formControlName="privacy" name="privacy" [required]="true" [labelPosition]="'before'" [disableRipple]="true">
          {{'general.form.privacyLabelPre' | translate}}
          <a href="{{'footer.privacyURL' | translate}}" target="_blank" rel="noopener" [title]="'general.form.privacyLinkTitle' | translate">
            {{'general.form.privacyLink' | translate }}
          </a>
          {{'general.form.privacyLabelMid' | translate}}
          <a href="{{'footer.tosURL' | translate}}" target="_blank" rel="noopener" [title]="'general.form.tosLinkTitle' | translate">
            {{'general.form.tosLink' | translate }}
          </a>
          {{'general.form.privacyLabelPost' | translate}}
        </mat-checkbox>
      </div>
      <se-error-list [errors]="errors"></se-error-list>
    </form>
  </div>

<button type="button" class="btn" [disabled]="!isBookingPossible()" (click)="submitRequest()" translate>
  {{'booking.summary.book' | translate }}
</button>
