import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'se-btn-back',
  templateUrl: './btn-back.component.html',
  styleUrls: ['./btn-back.component.scss']
})
export class BtnBackComponent implements OnInit {
  @Input() previous?: string;


  constructor(private location: Location, private router: Router) { }

  ngOnInit() {
  }


  goBack() {
    if (this.previous) {
      this.router.navigate([this.previous]);
    } else {
      this.location.back();
    }
  }
}
