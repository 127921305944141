import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[seSelectOnFocus]'
})
export class SelectOnFocusDirective {

  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    event.target.setSelectionRange(0, event.target.value.length);
  }

}
